.p-login-operate {
  height: 608px;
  min-width: 1100px;
  background-size: cover;
  background-position: 50%;
  background-color: #cccccc;
  background-repeat: no-repeat;
  background-image: url("/image/login/operate/bg.jpg");
}
.p-login-operate > .body {
  width: 1100px;
  display: flex;
  margin: 22px auto;
  align-items: center;
}
.p-login-operate > .body > .banner {
  margin-right: 48px;
}
.p-login-operate > .body > .form {
  width: 428px;
  height: 424px;
  text-align: center;
  padding: 9px 19px 17px 9px;
  background-repeat: no-repeat;
  background-image: url("/image/login/form.png");
}
.p-login-operate > .body > .form > .title {
  margin: 50px;
  font-size: 24px;
  text-align: center;
}
.p-login-operate > .body > .form > .username,
.p-login-operate > .body > .form > .password {
  display: flex;
  margin: 20px 50px;
  line-height: 30px;
  align-items: center;
  border-style: solid;
  border-color: #cccccc;
  border-width: 0px 0px 1px 0px;
}
.p-login-operate > .body > .form > .username > i,
.p-login-operate > .body > .form > .password > i {
  margin: 5px;
  font-size: 18px;
  color: #666666;
  line-height: initial;
}
.p-login-operate > .body > .form > .username > input,
.p-login-operate > .body > .form > .password > input {
  flex: 1;
  border: none;
  outline: none;
  font-size: 16px;
  padding: 0px 4px;
  line-height: inherit;
  background-color: transparent;
}
.p-login-operate > .body > .form > .username > input::placeholder,
.p-login-operate > .body > .form > .password > input::placeholder {
  font-size: 14px;
  color: #999999;
}
.p-login-operate > .body > .form > .username > input:-webkit-autofill,
.p-login-operate > .body > .form > .password > input:-webkit-autofill {
  font-size: 16px !important;
  background-color: transparent !important;
  transition: background-color 31536000s cubic-bezier(1, 0, 1, 0);
}
.p-login-operate > .body > .form > .username > input[type="password"],
.p-login-operate > .body > .form > .password > input[type="password"] {
  letter-spacing: 4px;
}
.p-login-operate > .body > .form > .username > input[type="password"]::placeholder,
.p-login-operate > .body > .form > .password > input[type="password"]::placeholder {
  letter-spacing: initial;
}
.p-login-operate > .body > .form > .other {
  margin: 20px 50px;
  text-align: right;
}
.p-login-operate > .body > .form > .other > a:not(:hover) {
  color: #666666;
}
.p-login-operate > .body > .form > .other > a:not(:last-child) {
  margin-right: 15px;
}
.p-login-operate > .body > .form > .option {
  margin: 40px 50px;
}
.p-login-operate > .body > .form > .option > button {
  width: 100%;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  color: #ffffff;
  line-height: 34px;
  border-radius: 4px;
  background-color: #41ac76;
}
.p-login-operate > .body > .form > .option > button > span {
  margin: 0px 10px;
}
